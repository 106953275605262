.App {
  text-align: center;
}
.btn-color {
  background-color: #7BA292;
}


.project-card {
  margin: .5rem;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
}

.project-card .p-card-footer {
  text-align: right;
}


.link-color {
  color: #7BA292;
}
body {
  margin: 0;
  overflow-x: hidden;
}
.shw {
  /* a sytble box shadow */
  box-shadow: 1px 4px 8px 0 rgba(0, 0, 0, 0.2);
}
.linkIcon {
  margin: 5px;
}

.header {
  width: 80%;
  display: flex;
  color: black;
  margin: auto;
  z-index: 10;
  
}
.education-list {
  width: 60%;
  text-align: left;
}

.publication-list {
  width: 60%;
  text-align: left;
}


.body {
  padding-left: 10%;
  height: 100vh;
  width: 100vw;
  display: flex;

}

i {
  color: #7ba292;
}
.left-container {  
  width: 25%;
  padding: 20px;

 
}
.right-container {

  width: 60%;
  padding: 20px;

}







